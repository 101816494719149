html {
  scroll-behavior: smooth; }

body {
  min-width: 360px;
  margin: 0;
  background-color: #f8f6f8;
  overflow-y: scroll; }

#global-container {
  margin: 0 auto;
  width: 940px;
  background-color: white;
  font-family: Helvetica;
  font-size: 15px; }
  #global-container .global-dummy-nav {
    height: 96px; }
    @media (max-width: 600px) {
      #global-container .global-dummy-nav {
        height: 58px; } }
  #global-container .global-page-container {
    padding-top: 5px; }
    #global-container .global-page-container .global-align-center {
      text-align: center; }
  @media (max-width: 940px) {
    #global-container {
      width: 100%; } }

ps-accordion {
  display: block; }
  ps-accordion .accordion-item {
    border: solid 1px;
    border-bottom: 0; }
    ps-accordion .accordion-item:last-child {
      border-bottom: solid 1px; }
    ps-accordion .accordion-item .label {
      background-color: gray;
      color: white;
      padding: 10px;
      cursor: pointer; }
    ps-accordion .accordion-item .content {
      display: none;
      text-align: left;
      padding: 5px; }
    ps-accordion .accordion-item.active .content {
      display: block; }

.ps-footer {
  padding: 10px;
  background-color: #363636;
  text-align: center; }
  .ps-footer a {
    color: #a9adb1;
    text-decoration: none;
    margin-right: 8px; }
    .ps-footer a:visited {
      color: #a9adb1; }

.ps-image-input img {
  display: block;
  margin: 10px 0;
  border: solid 1px;
  text-align: center;
  height: 300px;
  line-height: 300px;
  width: 550px; }

.ps-navigation-bar-desktop {
  position: fixed;
  top: 0;
  width: 940px;
  z-index: 500;
  user-select: none;
  color: #a9adb1; }
  .ps-navigation-bar-desktop__logo {
    display: flex;
    padding: 10px 0 0 10px;
    background-color: #363636; }
  .ps-navigation-bar-desktop__menu ul {
    width: 100%;
    height: auto;
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #363636;
    overflow: auto; }
    .ps-navigation-bar-desktop__menu ul li a,
    .ps-navigation-bar-desktop__menu ul li span {
      display: block;
      text-decoration: none;
      cursor: pointer;
      color: #a9adb1;
      float: left;
      padding: 10px; }
      .ps-navigation-bar-desktop__menu ul li a i,
      .ps-navigation-bar-desktop__menu ul li span i {
        margin-left: 8px; }
      .ps-navigation-bar-desktop__menu ul li a:hover,
      .ps-navigation-bar-desktop__menu ul li span:hover {
        background-color: #4d4d4d; }
  .ps-navigation-bar-desktop__products {
    width: 500px;
    background-color: #363636;
    padding-top: 0;
    padding-bottom: 8px;
    max-height: calc(100vh - 104px);
    overflow-y: auto; }
    .ps-navigation-bar-desktop__products a {
      display: block;
      text-decoration: none;
      cursor: pointer;
      color: #a9adb1; }
      .ps-navigation-bar-desktop__products a i {
        margin-left: 8px; }
      .ps-navigation-bar-desktop__products a.indent_1 {
        padding: 5px 20px; }
        .ps-navigation-bar-desktop__products a.indent_1:hover {
          background-color: #4d4d4d; }
      .ps-navigation-bar-desktop__products a.indent_2 {
        padding: 4px 40px; }
        .ps-navigation-bar-desktop__products a.indent_2:hover {
          background-color: #4d4d4d; }

.ps-navigation-bar-mobile {
  min-width: 360px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  user-select: none;
  color: #a9adb1; }
  .ps-navigation-bar-mobile__logo {
    display: flex;
    padding: 5px;
    background-color: #363636; }
    .ps-navigation-bar-mobile__logo__icons {
      display: inline-block;
      width: 100%;
      text-align: right; }
      .ps-navigation-bar-mobile__logo__icons i,
      .ps-navigation-bar-mobile__logo__icons a {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        color: white;
        cursor: pointer;
        text-decoration: none; }
  .ps-navigation-bar-mobile__menu ul {
    width: 100%;
    max-height: calc(100vh - 58px);
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #363636;
    overflow-y: auto; }
    .ps-navigation-bar-mobile__menu ul li {
      position: relative;
      border-top: solid 1px white; }
      .ps-navigation-bar-mobile__menu ul li:last-of-type {
        border-bottom: solid 1px white; }
      .ps-navigation-bar-mobile__menu ul li a,
      .ps-navigation-bar-mobile__menu ul li span {
        display: block;
        text-decoration: none;
        cursor: pointer;
        color: #a9adb1;
        padding: 11px 10px; }
        .ps-navigation-bar-mobile__menu ul li a i,
        .ps-navigation-bar-mobile__menu ul li span i {
          margin-left: 8px; }
      .ps-navigation-bar-mobile__menu ul li .expandable-icon {
        position: absolute;
        top: 14px;
        left: 75px;
        cursor: pointer; }
  .ps-navigation-bar-mobile__products {
    width: 400px;
    background-color: #363636;
    padding-top: 0; }
    .ps-navigation-bar-mobile__products a {
      display: block;
      text-decoration: none;
      cursor: pointer;
      color: #a9adb1; }
      .ps-navigation-bar-mobile__products a i {
        margin-left: 8px; }
      .ps-navigation-bar-mobile__products a.indent_1 {
        padding: 0 20px;
        line-height: 40px;
        background: #403f3f;
        margin: 1px; }
      .ps-navigation-bar-mobile__products a.indent_2 {
        padding: 11px 40px;
        background: #403f3f;
        margin: 1px; }

.ps-loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .ps-loading-indicator__pulse {
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #363636;
    top: 50%;
    left: 50%;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 120px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 rgba(59, 58, 52, 0.4);
    animation: pulse 2s infinite; }

@-webkit-keyframes ps-loading-indicator__pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(59, 58, 52, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(59, 58, 52, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(59, 58, 52, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(59, 58, 52, 0.4);
    box-shadow: 0 0 0 0 rgba(59, 58, 52, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(59, 58, 52, 0);
    box-shadow: 0 0 0 30px rgba(59, 58, 52, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(59, 58, 52, 0);
    box-shadow: 0 0 0 0 rgba(59, 58, 52, 0); } }

.ps-slider {
  margin: 10px auto;
  width: 550px;
  height: 590px;
  background-color: #363636;
  position: relative;
  overflow: hidden;
  border-left: 5px solid #363636;
  border-right: 5px solid #363636; }
  @media (max-width: 600px) {
    .ps-slider {
      width: 370px;
      height: 490px; } }
  @media (max-width: 360px) {
    .ps-slider {
      width: 330px;
      height: 490px; } }
  .ps-slider .ps-slider-slide {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%; }
    .ps-slider .ps-slider-slide a {
      text-decoration: none; }
    .ps-slider .ps-slider-slide-title {
      text-transform: uppercase;
      font-weight: 700;
      background-color: #363636;
      text-align: center;
      height: 35px;
      line-height: 35px;
      color: #a9adb1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .ps-slider .ps-slider-slide-image {
      height: 300px;
      background-color: #363636;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-bottom: 5px solid #363636;
      cursor: pointer; }
      @media (max-width: 600px) {
        .ps-slider .ps-slider-slide-image {
          height: 200px; } }
      @media (max-width: 360px) {
        .ps-slider .ps-slider-slide-image {
          height: 180px; } }
    .ps-slider .ps-slider-slide-content {
      overflow: hidden;
      box-sizing: border-box;
      padding: 20px 20px 0;
      height: 200px;
      background-color: #dde3e6;
      color: #042c3b;
      overflow-y: auto; }
      @media (max-width: 600px) {
        .ps-slider .ps-slider-slide-content {
          height: 200px;
          padding: 10px 10px 0;
          font-size: 14px; } }
      @media (max-width: 360px) {
        .ps-slider .ps-slider-slide-content {
          height: 220px; } }
  .ps-slider-navigation, .ps-slider-navigation-previous, .ps-slider-navigation-next {
    position: absolute;
    bottom: 10px;
    align-self: center;
    width: 30px;
    height: 30px;
    z-index: 4; }
    .ps-slider-navigation-container {
      height: 50px; }
      .ps-slider-navigation-container button {
        background-color: initial;
        border: 0;
        cursor: pointer;
        font-size: 25px;
        color: white;
        outline: none; }
        .ps-slider-navigation-container button[disabled] {
          opacity: 1;
          color: #a9adb1;
          cursor: default; }
    .ps-slider-navigation-previous {
      left: 15px; }
    .ps-slider-navigation-bullets {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 4; }
      .ps-slider-navigation-bullets .ps-slider-bullet {
        width: 10px;
        height: 10px;
        margin: 0 6px 19px;
        padding: 0;
        background-color: #a9adb1;
        border-radius: 6px; }
        @media (max-width: 600px) {
          .ps-slider-navigation-bullets .ps-slider-bullet {
            width: 6px;
            height: 6px;
            margin: 0 5px 22px; } }
        .ps-slider-navigation-bullets .ps-slider-bullet.bullet-active {
          background-color: white;
          cursor: default; }
    .ps-slider-navigation-next {
      right: 15px; }
  .ps-slider img:first-child {
    z-index: 3; }

@-webkit-keyframes current-right {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-100%); } }

@keyframes current-right {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-100%); } }

.current-right {
  animation-name: current-right;
  animation-duration: 1s; }

@-webkit-keyframes current-left {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100%); } }

@keyframes current-left {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100%); } }

.current-left {
  animation-name: current-left;
  animation-duration: 1s; }

@-webkit-keyframes next-right {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0%); } }

@keyframes next-right {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

.next-right {
  animation-name: next-right;
  animation-duration: 1s; }

@-webkit-keyframes next-left {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }

@keyframes next-left {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }

.next-left {
  animation-name: next-left;
  animation-duration: 1s; }

.ps-pdf-viewer {
  display: block; }
  .ps-pdf-viewer-toolbar {
    background-color: #363636; }
    .ps-pdf-viewer-toolbar-pages {
      display: inline-block;
      padding: 0 15px;
      color: sandybrown; }

button.ps-common-button, button.ps-common-button-text, button.ps-common-button-icon {
  background-color: #363636;
  outline: 0;
  color: sandybrown;
  cursor: pointer; }
  button.ps-common-button:hover, button.ps-common-button-text:hover, button.ps-common-button-icon:hover {
    background-color: #4e4e4e; }
  button.ps-common-button-text {
    padding: 5px;
    border-color: sandybrown; }
  button.ps-common-button-icon {
    border: none;
    padding: 0;
    width: 40px;
    height: 40px; }

.ps-cookies {
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 10px;
  background-color: #a9adb1;
  z-index: 10000;
  color: white; }
  .ps-cookies button {
    margin-top: 16px;
    border-color: white;
    color: white; }
  .ps-cookies span {
    margin-right: 16px; }

.home-page {
  display: block; }

.ps-home-item {
  display: block;
  margin-bottom: 10px;
  padding: 10px; }
  .ps-home-item__header-left {
    display: inline-block;
    background-color: green;
    padding: 12px;
    color: white;
    height: 100%;
    width: 100px; }
  .ps-home-item__header {
    display: inline-block;
    width: calc(100% - 150px);
    margin: 0 13px; }
    .ps-home-item__header__color-bar {
      height: 25px;
      width: 100%; }
    .ps-home-item__header__title {
      padding: 5px;
      text-transform: uppercase;
      color: gray;
      font-weight: 800; }

.content-page {
  display: flex; }
  .content-page__left {
    width: 220px;
    text-align: center; }
    @media (max-width: 600px) {
      .content-page__left {
        display: none; } }
    .content-page__left img {
      display: block;
      margin: 20px auto; }
  .content-page__main {
    flex: auto;
    width: 1px;
    padding: 0 20px 20px; }
    .content-page__main a {
      text-decoration: none;
      color: blue; }
      .content-page__main a:visited {
        color: blue; }

.products-page {
  padding: 0 20px 20px 20px; }
  .products-page__category__title {
    text-align: center;
    color: sandybrown; }
    .products-page__category__title h1 {
      margin: 0 40px;
      padding-top: 20px; }
  .products-page__group__container__item {
    background-color: #e9e8e8;
    margin: 30px 0;
    padding: 1px 10px; }
    .products-page__group__container__item ul {
      padding-left: 20px; }
      .products-page__group__container__item ul li:marker {
        font-size: 30px; }
    .products-page__group__container__item h3 {
      text-align: center;
      padding-top: 15px; }
    .products-page__group__container__item__db {
      margin: 20px 0; }

ps-product-accordion {
  display: block;
  margin: 10px 0; }
  ps-product-accordion > span {
    background-color: #363636;
    color: sandybrown;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px; }
  ps-product-accordion .accordion-item {
    border: solid 1px;
    border-bottom: solid 1px; }
    ps-product-accordion .accordion-item .accordion-label {
      background-color: #363636;
      color: #a9adb1;
      padding-left: 10px;
      line-height: 32px; }
      ps-product-accordion .accordion-item .accordion-label span {
        cursor: pointer; }
    ps-product-accordion .accordion-item .accordion-content {
      user-select: none;
      text-align: center; }
      ps-product-accordion .accordion-item .accordion-content-top {
        text-align: left;
        padding: 0 8px;
        border-bottom: solid 1px #363636;
        box-sizing: border-box;
        background-color: #a9adb1; }
        ps-product-accordion .accordion-item .accordion-content-top i {
          margin: 5px 5px 0;
          font-size: 30px;
          text-align: center;
          width: 50px;
          height: 40px;
          line-height: 40px;
          color: #363636;
          cursor: pointer; }
          ps-product-accordion .accordion-item .accordion-content-top i.view-button-active {
            color: sandybrown;
            border-radius: 10px 10px 0 0;
            background-color: #363636; }
      ps-product-accordion .accordion-item .accordion-content-bottom {
        overflow: hidden; }
        ps-product-accordion .accordion-item .accordion-content-bottom-image {
          height: 400px; }
          ps-product-accordion .accordion-item .accordion-content-bottom-image__toolbar {
            width: 100%;
            height: 40px;
            background-color: #363636; }
          ps-product-accordion .accordion-item .accordion-content-bottom-image__img {
            height: calc(100% - 40px);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center; }
        ps-product-accordion .accordion-item .accordion-content-bottom-pdf iframe {
          width: 100%;
          height: 600px; }
  ps-product-accordion button.label {
    padding: 0;
    outline: none;
    border: none;
    color: #a9adb1;
    background-color: transparent;
    cursor: pointer; }
  ps-product-accordion button.button-loading, ps-product-accordion button.button-exit, ps-product-accordion button.button-arrow-down {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    width: 30px;
    color: sandybrown; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }
  ps-product-accordion button.button-loading {
    animation: spinner 0.6s linear infinite; }

.ps-product-box {
  display: block;
  text-align: center; }
  .ps-product-box .item-box {
    display: inline-block;
    margin: 10px; }
    .ps-product-box .item-box-name {
      border-radius: 10px 10px 0 0;
      background-color: #363636;
      color: #a9adb1;
      padding: 5px;
      text-align: center; }
    .ps-product-box .item-box-image {
      width: 200px;
      height: 250px;
      border-radius: 0 0 10px 10px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-color: white;
      cursor: pointer; }

.mp-edit-slider-page .accordion-item.invalid .label {
  background-color: red; }

.mp-edit-slider-page .accordion-item.valid .label {
  background-color: green; }

.mp-edit-slider-page-item {
  margin: 20px; }
  .mp-edit-slider-page-item span {
    display: inline-block;
    width: 50px; }
  .mp-edit-slider-page-item input[type="text"] {
    width: 400px; }
  .mp-edit-slider-page-item textarea {
    display: block;
    width: 100%;
    height: 200px; }

.mp-edit-category-page .accordion-item.invalid .label {
  background-color: red; }

.mp-edit-category-page .accordion-item.valid .label {
  background-color: green; }

.mp-edit-sub-category-page .accordion-item.invalid .label {
  background-color: red; }

.mp-edit-sub-category-page .accordion-item.valid .label {
  background-color: green; }

.mp-edit-sub-category-page-row {
  margin: 10px; }
  .mp-edit-sub-category-page-row span {
    display: inline-block;
    width: 80px; }
  .mp-edit-sub-category-page-row input[type="text"] {
    width: 700px; }
  .mp-edit-sub-category-page-row textarea {
    display: block;
    width: 100%;
    min-height: 300px; }

.mp-edit-item-page .accordion-item.invalid .label {
  background-color: red; }

.mp-edit-item-page .accordion-item.valid .label {
  background-color: green; }

.mp-edit-item-page .accordion-item .row-item {
  margin: 10px; }

.mp-edit-item-page .ps-image-input img {
  width: auto; }

.mp-edit-item-page .ps-pdf-viewer {
  width: 400px; }
